<template>
  <div class="row">
    <div class="col-lg-3">
      <ListingsFilter ref="listings-filter" @changed="filterChanged"/>
    </div>
    <div class="col-lg-9">
      <div class="card card-custom card-stretch">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
          <div class="card-title">
            <h3 class="card-label">Promosyon Kodları
              <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ totalRows }} adet rezervasyon bulunmakta</span>
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
              :to="{ name: 'create-promotion-code'}"
              v-slot="{ href }"
            >
              <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
                <span class="svg-icon">
                  <inline-svg src="media/svg/icons/Code/Plus.svg" />
                </span>
                <span class="d-md-inline">Yeni Kod Oluştur</span>
              </a>
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-10">
            <div class="row align-items-center">
              <div class="col-md-6 my-2 my-md-0">
                <div class="input-icon">
                  <input type="text" class="form-control form-control-solid" placeholder="Ara..." v-model="searchQuery">
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppliedFilter :key="appliedFilterKey" @changed="appliedFilterChanged"/>
          </div>
          <div>
            <ListingTable ref="table" :filter="searchQuery" @total-rows="setTotalRows"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListingsFilter from "@/view/pages/promotion-code/components/listings/Filter";
import AppliedFilter from "@/view/pages/promotion-code/components/listings/AppliedFilter";
import ListingTable from "@/view/pages/promotion-code/components/listings/ListingTable";

export default {
  components: {
    ListingsFilter,
    AppliedFilter,
    ListingTable
  },
  data() {
    return {
      searchQuery: '',
      appliedFilterKey: 0,
      totalRows: 0,
    }
  },
  methods: {
    filterChanged() {
      this.appliedFilterKey += 1;
      this.$refs["table"].get();
    },
    appliedFilterChanged() {
      this.appliedFilterKey += 1;
      this.$refs["listings-filter"].init();
    },
    setTotalRows(rows) {
      this.totalRows = rows;
    },
  }
}
</script>

<style>

</style>