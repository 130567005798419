<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Filtrele
          <span class="d-block text-muted pt-2 font-size-sm"></span>
        </h3>
      </div>
    </div>
    <div class="card-body">

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Durum</div>
        <div class="checkbox-list">
          <label v-for="item in states" :key="item.value" class="checkbox checkbox-pill font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light" :class="`checkbox-light-${item.color}`">
            <input type="checkbox" :value="item.value" v-model="checkedStates"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Müşteri Tipi</div>
        <div class="checkbox-list">
          <label v-for="item in customerTypes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedCustomerTypes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">İndirim Türü</div>
        <div class="checkbox-list">
          <label v-for="item in discountTypes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedDiscountTypes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkedDiscountTypes: [],
      checkedCustomerTypes: [],
      checkedStates: [],
      timeout: null,
    }
  },
  computed: {
    discountTypes() {
      let arr = [
        {
          value: 'PCT',
          name: 'Yüzde',
        },
        {
          value: 'TRY',
          name: 'Türk Lirasi',
        },
        {
          value: 'USD',
          name: 'Dolar',
        },
        {
          value: 'EUR',
          name: 'Euro',
        }
      ];
      return arr;
    },

    customerTypes() {
      let arr = [
        {
          value: 1,
          name: 'Üyelere Özel',
        },
        {
          value: 2,
          name: 'Tüm Kullanıcılar',
        }
      ];
      return arr;
    },

    states() {
      let arr = [
        {
          value: 1,
          name: 'Aktif',
          color: 'success'
        },
        {
          value: 2,
          name: 'Planlı',
          color: 'warning'
        },
        {
          value: 3,
          name: 'Süresi Dolmuş',
          color: 'secodnary'
        }
      ];
      return arr;
    },
  },
   watch: {
    checkedDiscountTypes(val) {
      let arr = [];
      val.forEach((el) => {
        this.discountTypes.find((theme) => {
          if (theme.value === el) {
            arr.push({value: el, name: theme.name});
          }
        })
      })
      this.updateFilter('discountTypes', {groupName: 'İndirim Türü', values: val, items: arr});
    },
    checkedCustomerTypes(val) {
      let arr = [];
      val.forEach((el) => {
        this.customerTypes.find((theme) => {
          if (theme.value === el) {
            arr.push({value: el, name: theme.name});
          }
        })
      })
      this.updateFilter('customerTypes', {groupName: 'Müşteri Tipi', values: val, items: arr});
    },
    checkedStates(val) {
      let arr = [];
      val.forEach((el) => {
        this.states.find((state) => {
          if (state.value === el) {
            arr.push({value: el, name: state.name});
          }
        })
      })
      this.updateFilter('states', {groupName: 'Durum', values: val, items: arr});
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const storage = this.openStorage();
      if (!storage) {
        this.checkedDiscountTypes = [];
        this.checkedCustomerTypes = [];
        this.checkedStates = [];
      } else {
        this.checkedDiscountTypes = storage.discountTypes.values;
        this.checkedCustomerTypes = storage.customerTypes.values;
        this.checkedStates = storage.states.values;
      }
      this.dateRangePickerKey += 1;
    },
    openStorage () {
      return JSON.parse(sessionStorage.getItem('promotion-listings-filter'));
    },
    saveStorage (form) {
      sessionStorage.setItem('promotion-listings-filter', JSON.stringify(form));
    },
    updateFilter (input, value) {
      let storedFilter = this.openStorage();
      if (!storedFilter) storedFilter = {};
    
      storedFilter[input] = value;
      this.saveStorage(storedFilter);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('changed');
      }, 750);
    }
  }
}
</script>

<style>

</style>