<template>
  <div>
    <b-table
        class="table table-head-custom table-vertical-center"
        thead-class="mb-8"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir rezervasyon bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="codes"
        :fields="codesDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-5"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(code)="data">
          <span class="text-body font-weight-bolder d-block font-size-lg" style="letter-spacing: 0.2em;">
            {{ data.item.code }}
          </span>
        </template>
        <template #cell(discount)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
            {{ data.item.discount.amount }} 
            <span v-if="data.item.discount.unit === 'PCT'">%</span>
            <span v-else>{{ data.item.discount.unit }}</span>
          </span>
          <div>
            <span class="text-muted font-size-sm font-weight-bold" v-if="data.item.productType === 1">Tüm ürünlerde</span>
            <span class="text-muted font-size-sm font-weight-bold" v-if="data.item.productType === 2">Seçili ürünlerde</span>
          </div>
        </template>
        <template #cell(usage)="data">
          <div class="d-flex flex-column w-100 mr-2">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="text-dark-50 mr-2 font-size-sm font-weight-bold">{{ usagePercentage(data.item.usage.used, data.item.usage.totalCopy) }}%</span>
              <span class="text-dark-50 font-size-sm font-weight-bold">{{ data.item.usage.used }} / {{ data.item.usage.totalCopy }}</span>
            </div>
            <div class="progress progress-xs w-100">
              <div class="progress-bar bg-success" :class="{'bg-danger': usagePercentage(data.item.usage.used, data.item.usage.totalCopy) === 100 }" role="progressbar" :style="`width: ${usagePercentage(data.item.usage.used, data.item.usage.totalCopy)}%;`" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-success font-weight-bold label-inline" v-if="codeStatus(data.item.valid.from, data.item.valid.to) == 1">Aktif</span>
          <span class="label label-lg label-warning font-weight-bold label-inline" v-if="codeStatus(data.item.valid.from, data.item.valid.to) == 2">Planlı</span>
          <span class="label label-lg label-secondary font-weight-bold label-inline" v-if="codeStatus(data.item.valid.from, data.item.valid.to) == 3">Süresi Doldu</span>
        </template>
        
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'promotion-code-details', params: { code: data.item.code }}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
              </span>
            </a>
          </router-link>
        </template>
    </b-table>
    <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        class="my-0"
        size="md"
      >
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';

export default {
  props: ['filter'],
  data() {
    return {
      codes: [],
      codesDataTableFields: [
        { key: 'code', label: 'PROMOSYON KODU', sortable: false },
        { key: 'discount', label: 'İNDİRİM', sortable: false },
        { key: 'usage', label: 'KULLANIM', sortable: false },
        { key: 'status', label: 'DURUM', sortable: false,  class: 'text-center' },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
      isBusy: false
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.isBusy = true;
      const storage = this.openStorage();
      let discountTypes = storage.discountTypes.values;
      let customerTypes = storage.customerTypes.values;
      let states = storage.states.values;

      if (discountTypes.length > 0) {
        var typeParam = 'type=' + discountTypes.join();
      }
      if (customerTypes.length > 0) {
        var customerParam = 'customer=' + customerTypes.join();
      }
      if (states.length > 0) {
        var stateParam = 'state=' + states.join();
      }

      ApiService.get(`promotion-code/listings.req.php?${typeParam}&${customerParam}&${stateParam}`)
      .then(({ data }) => {
        this.codes = data.codes;
        this.totalRows = this.codes.length;
        setTimeout(() => {
          this.isBusy = false;  
          this.$emit('total-rows', this.totalRows);
        }, 500);
      })
      .catch(() => {
        this.codes = [];
        this.totalRows = 0;
        setTimeout(() => {
          this.isBusy = false;  
        }, 500);
      });
    },
    usagePercentage(used, total) {
      return parseInt((100 * used) / total);
    },
    codeStatus(from, to) {
      const today = moment().format('YYYY-MM-DD');
      if (moment(today).isBefore(from)) {
        return 2;
      } else if (moment(today).isSameOrAfter(from) && moment(today).isSameOrBefore(to)) {
        return 1;
      } else if (moment(today).isAfter(to)) {
        return 3;
      }
    },
    openStorage () {
      return JSON.parse(sessionStorage.getItem('promotion-listings-filter'));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1
    },
  }

}
</script>

<style>

</style>